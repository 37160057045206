/**
 * Import FontAwesome icons into library
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faBars,
  faBicycle,
  faCaretLeft,
  faCaretRight,
  faChartBar,
  faCheck,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faClone,
  faCog,
  faCompress,
  faCrosshairs,
  faDirections,
  faExclamation,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileImport,
  faGlobe,
  faGlobeAmericas,
  faHourglass,
  faInfo,
  faInfoCircle,
  faLink,
  faLongArrowAltRight,
  faMedal,
  faMinus,
  faMountain,
  faPen,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faRoute,
  faSave,
  faSearch,
  faShareAlt,
  faSignInAlt,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faSpinner,
  faThumbsUp,
  faTimes,
  faTrashAlt,
  faUndo,
  faUser,
  faUserCheck,
  faUsers,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';

import {
  faThumbsUp as farThumbsUp,
  faSquare,
  faStickyNote,
} from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowLeft,
  faBars,
  faBicycle,
  faCaretLeft,
  faCaretRight,
  faChartBar,
  faCheck,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faClone,
  faCog,
  faCompress,
  faCrosshairs,
  faDirections,
  faExclamation,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFacebook,
  faFileImport,
  faGlobe,
  faGlobeAmericas,
  faHourglass,
  faInfo,
  faInfoCircle,
  faLink,
  faLongArrowAltRight,
  faMedal,
  faMinus,
  faMountain,
  faPen,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faRoute,
  faSave,
  faSearch,
  faShareAlt,
  faSignInAlt,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faSpinner,
  faStickyNote,
  faSquare,
  faThumbsUp,
  farThumbsUp,
  faTimes,
  faTrashAlt,
  faTwitter,
  faUndo,
  faUser,
  faUserCheck,
  faUsers,
  faWalking,
);
